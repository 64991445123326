import Vue from "vue";
import Router from "vue-router";

// Importe seus módulos de rotas dinâmicas aqui
import developer from "./type_routes/developer";
import global from "./type_routes/global";
import master from "./type_routes/master";
import admin from "./type_routes/admin";
import supervisor from "./type_routes/supervisor";
import manager from "./type_routes/manager";
import seller from "./type_routes/seller";
import dealer from "./type_routes/dealer";
import factory from "./type_routes/factories";
import stopper from "./type_routes/stopper";

// Office imports
import office_operation_global from "./type_routes/office_operation_global";
import office_operation_master from "./type_routes/office_operation_master";
import office_operation_franchise from "./type_routes/office_operation_franchise";

Vue.use(Router);

const requireAuth = async (to, from, next) => {
  try {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data && data.user) {
      next(); // Continua na mesma rota se autenticado
    } else {
      next("/login"); // Redireciona para o login se não autenticado
    }
  } catch (error) {
    console.error("Erro ao verificar autenticação:", error);
    next("/login"); // Redireciona para o login em caso de erro
  }
};

const router = new Router({
  mode: "history",
  routes: [
    { path: "/", name: "login", redirect: "/login" },
    { path: "/login", component: () => import("./pages/login/login_page.vue") },
    { path: "/qrcode", component: () => import("./pages/Qrcode") },
    {
      path: "/product/code/:code",
      component: () => import("./pages/Products/viewqrcode.vue"),
    },
    {
      path: "/product/:code",
      component: () => import("./pages/Products/viewqrcode.vue"),
    },
    {
      path: "/volume/code/:code",
      component: () => import("./pages/Volumes/view.vue"),
    },
    {
      path: "/forgot_password",
      component: () =>
        import("./pages/forgot_password/forgot_password_page.vue"),
    },
    {
      path: "/product/view/:id",
      component: () => import("./pages/Products/mobile.vue"),
    },
    {
      path: "/profile",
      component: () => import("./pages/Profile/"),
      beforeEnter: requireAuth,
    },
    {
      path: "/feed",
      component: () => import("./pages/Post/"),
      beforeEnter: requireAuth,
    },
    {
      path: "/likes",
      component: () => import("./pages/Post/likes.vue"),
      beforeEnter: requireAuth,
    },
    { path: "/talks", component: () => import("./pages/Talks") },
    {
      path: "/taks/projecto/:id",
      component: () => import("./pages/Tasks/tasks.vue"),
    },
    {
      path: "/contacts",
      component: () => import("./pages/Talks/contacts.vue"),
    },
    {
      path: "/myGroups",
      component: () => import("./pages/Talks/myGroups.vue"),
    },
    { path: "/chats", component: () => import("./pages/Talks/chats.vue") },
    {
      path: "/feed/:id",
      component: () => import("./pages/Post/"),
      beforeEnter: requireAuth,
    },
    { path: "/certificado", component: () => import("./pages/Certificado") },

    {
      path: "/clients",
      component: () => import("./pages/Clients"),
      beforeEnter: requireAuth,
    }
  ],
});

const addDynamicRoutes = (userType, operationType) => {
  if (userType === "developer") {
    developer.options.routes.forEach((route) => router.addRoute(route));
  } else if (userType === "global") {
    global.options.routes.forEach((route) => router.addRoute(route));
  } else if (userType === "master") {
    master.options.routes.forEach((route) => router.addRoute(route));
  } else if (userType === "admin") {
    admin.options.routes.forEach((route) => router.addRoute(route));
  } else if (userType === "supervisor") {
    supervisor.options.routes.forEach((route) => router.addRoute(route));
  } else if (userType === "manager") {
    manager.options.routes.forEach((route) => router.addRoute(route));
  } else if (userType === "seller") {
    seller.options.routes.forEach((route) => router.addRoute(route));
  } else if (userType === "dealer") {
    dealer.options.routes.forEach((route) => router.addRoute(route));
  } else if (userType === "stopper") {
    stopper.options.routes.forEach((route) => router.addRoute(route));
  } else if (userType === "office" && operationType === "global") {
    office_operation_global.options.routes.forEach((route) =>
      router.addRoute(route)
    );
  } else if (userType === "office" && operationType === "master") {
    office_operation_master.options.routes.forEach((route) =>
      router.addRoute(route)
    );
  } else if (userType === "office" && operationType === "franchise") {
    office_operation_franchise.options.routes.forEach((route) =>
      router.addRoute(route)
    );
  } else if (userType === "factories" && operationType === "factories") {
    factory.options.routes.forEach((route) => router.addRoute(route));
  }
};

const initializeRoutes = async () => {
  try {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data && data.user) {
      const { user } = data;
      addDynamicRoutes(user.type, user.operation);
    }
  } catch (error) {
    console.error("Erro ao inicializar rotas:", error);
  }
};

initializeRoutes();

// Redireciona qualquer rota não encontrada para a raiz
router.addRoute({ path: "*", redirect: "/" });

export default router;
