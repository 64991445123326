import Vue from "vue";
import Router from "vue-router";

const routes = [];

const requireAuth = async (to, from, next) => {
  const data = JSON.parse(localStorage.getItem("user"));
  if (data.user) {
    next();
  } else {
    next("/login");
  }
};


routes.push({
  path: "/indications/:id?",
  component: () =>
    import("../pages/Indications"),
  beforeEnter: requireAuth,
});

//Plano de Expanção
routes.push({
  path: "/expansion_plan",
  component: () => import("../pages/ExpansionPlan/dash"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/my_expansion_plan/:country_id?",
  component: () => import("../pages/ExpansionPlan"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/unity/:id/scale",
  component: () => import("../pages/Admin/Scale"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/unity/:id/view",
  component: () => import("../components/Unity/view.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/posts",
  component: () => import("../pages/Post/admin.vue"),
  beforeEnter: requireAuth,
});

//OLD
routes.push({
  path: "/dashboard-old",
  component: () => import("../pages/Dash"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/dashboard/dre-result-analysis",
  component: () => import("../pages/DreResultAnalysis"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/dashboard",
  component: () => import("../pages/Dashboard"),
  beforeEnter: requireAuth,
});

// routes.push({
//     path: '/dashboard',
//     component: () =>
//         import ('../pages/Admin/Dashboard'),
//     beforeEnter: requireAuth
// });
// routes.push({
//     path: '/dashboard-test',
//     component: () =>
//         import ('../pages/Admin/DashboardTest'),
//     beforeEnter: requireAuth
// });
routes.push({
  path: "/units",
  component: () => import("../pages/Admin/Units"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/unity/:id/users",
  component: () => import("../pages/Admin/Units/users"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/units/:id/users/recover",
  component: () => import("../pages/Admin/Units/users_recover"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/dealers",
  component: () => import("../pages/Admin/Dealer/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/supervisors",
  component: () => import("../pages/Admin/Supervisor/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/cash_flow/:id",
  component: () => import("../pages/Admin/CashFlow/"),
  beforeEnter: requireAuth,
});
routes.push({
  path: "/cash_flow/:id/list",
  component: () => import("../pages/Admin/CashFlow/list.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/sales/:type/:id",
  component: () => import("../pages/Admin/Sale/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/goals",
  component: () => import("../pages/Admin/Goals/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/goal/:id",
  component: () => import("../pages/Admin/Goals/view.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/goal/:id/ranking",
  component: () => import("../pages/Admin/Goals/view_ranking.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/awards",
  component: () => import("../pages/Admin/Award/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/bombs",
  component: () => import("../pages/Admin/Bombs/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/call/:type/:id",
  component: () => import("../pages/Admin/Call/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/messages",
  component: () => import("../pages/Admin/Message/"),
  beforeEnter: requireAuth,
});

//SELLER MANAGER
routes.push({
  path: "/user/dash/:id",
  component: () => import("../pages/Admin/Seller"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/my_profile/:id",
  component: () => import("../pages/Admin/Seller/Pages/Profile"),
});

routes.push({
  path: "/my_earnings/:id",
  component: () => import("../pages/Admin/Seller/Pages/Earnings"),
});

routes.push({
  path: "/my_sales/:id",
  component: () => import("../pages/Admin/Seller/Pages/MySales"),
});

routes.push({
  path: "/my_clients/:id",
  component: () => import("../pages/Admin/Seller/Pages/MyClients"),
});

routes.push({
  path: "/my_shopping/:id",
  component: () => import("../pages/Admin/Seller/Pages/MyShopping"),
});

routes.push({
  path: "/my_talks/:id",
  component: () => import("../pages/Admin/Seller/Pages/Talks"),
});

routes.push({
  path: "/talks",
  component: () => import("../pages/Talks2"),
});
routes.push({
  path: "/messages/:id",
  component: () => import("../pages/Admin/Seller/Pages/Message/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/my_goals/:id",
  component: () => import("../pages/Admin/Seller/Pages/MyGoals"),
});

routes.push({
  path: "/my_awards/:id",
  component: () => import("../pages/Admin/Seller/Pages/MyAwards"),
});

routes.push({
  path: "/my_catalog/:id",
  component: () => import("../pages/Admin/Seller/Pages/MyCatalog"),
});

routes.push({
  path: "/my_talks/:id",
  component: () => import("../pages/Admin/Seller/Pages/Talks"),
});

routes.push({
  path: "/my_talks2",
  component: () => import("../pages/Talks2"),
});
routes.push({
  path: "/messages/:id",
  component: () => import("../pages/Admin/Seller/Pages/Message/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/posts/:id",
  component: () => import("../pages/Post/admin.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/academy/index",
  component: () => import("../pages/Academy/index.vue"),
});

routes.push({
  path: "/academy/module",
  component: () => import("../pages/Academy/Categories"),
});

routes.push({
  path: "/academy_categories",
  component: () => import("../pages/Academy/Categories"),
});

routes.push({
  path: "/academy_class",
  component: () => import("../pages/Academy/Class"),
});

routes.push({
  path: "/academy_class_file",
  component: () => import("../pages/Academy/Files"),
});

routes.push({
  path: "/academy_assessment",
  component: () => import("../pages/Academy/Assessments"),
});

routes.push({
  path: "/academy_assessment/:category",
  component: () => import("../pages/Academy/Assessments/category-class.vue"),
});

routes.push({
  path: "/academy_assessment/:category/:lesson",
  component: () => import("../pages/Academy/Assessments/class-assessment.vue"),
});

routes.push({
  path: "/my_academy",
  component: () => import("../pages/Academy/view"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/my_academy/:category",
  component: () => import("../pages/Academy/view/category.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/my_academy/:category/:lesson",
  component: () => import("../pages/Academy/view/lesson.vue"),
  beforeEnter: requireAuth,
});

// routes.push({
//     path: "/my_academy",
//     component: () =>
//         import ("../pages/Seller/Academy/"),
//     beforeEnter: requireAuth,
// });

// routes.push({
//     path: "/my_academy/:category",
//     component: () =>
//         import ("../pages/Seller/Academy/category.vue"),
//     beforeEnter: requireAuth,
// });

// routes.push({
//     path: "/my_academy/:category/:lesson",
//     component: () =>
//         import ("../pages/Seller/Academy/lesson.vue"),
//     beforeEnter: requireAuth,
// });

routes.push({
  path: "/my_email/:id",
  component: () => import("../pages/Admin/Seller/Pages/MyEmail"),
});

// routes.push({
//     path: '/users',
//     component: () =>
//         import ('../pages/Admin/Users'),
// });

routes.push({
  path: "/users",
  component: () => import("../pages/Users"),
});
routes.push({
  path: "/users/recover",
  component: () => import("../pages/Users/recovers.vue"),
});

routes.push({
  path: "/my_talks",
  component: () => import("../pages/Admin/Talks"),
});

routes.push({
  path: "/cash_flow/:id",
  component: () => import("../pages/Admin/CashFlow/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/my_email",
  component: () => import("../pages/Admin/MyEmail"),
});

routes.push({
  path: "/my_email/:id",
  component: () => import("../pages/Admin/Seller/Pages/MyEmail"),
});

routes.push({
  path: "/users",
  component: () => import("../pages/Admin/Users"),
});

routes.push({
  path: "/my_talks",
  component: () => import("../pages/Admin/Talks"),
});

routes.push({
  path: "/testes",
  component: () => import("../pages/Testes"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/rankings",
  component: () => import("../pages/Rankings/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/best_seller",
  component: () => import("../pages/Best_seller/"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/birthdays",
  component: () => import("../pages/Birthdays/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/catalogs",
  component: () => import("../pages/Catalogs/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/catalog/:id/:name",
  component: () => import("../pages/Catalogs/list"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/catalog/module/:id/:name",
  component: () => import("../pages/Catalogs/list_module"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/catalog/:id/category/:category",
  component: () => import("../pages/Catalogs/list"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/catalog/master",
  component: () => import("../pages/Catalogs/master"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/product/:product_id/:name",
  component: () => import("../pages/Catalogs/view"),
  beforeEnter: requireAuth,
});

//TODO
routes.push({
  path: "/tasks",
  component: () => import("../pages/Tasks"),
});

routes.push({
  path: "/task/:id",
  component: () => import("../pages/Tasks/viewTask.vue"),
});

routes.push({
  path: "/late",
  component: () => import("../pages/TasksLate"),
});

routes.push({
  path: "/today",
  component: () => import("../pages/TasksToday"),
});

routes.push({
  path: "/products/dashbord",
  component: () =>
    import("../pages/ProductsDash"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/products/dashbord/view/:id",
  component: () =>
    import("../pages/ProductsDash/view"),
  beforeEnter: requireAuth,
});
routes.push({
  path: "/products/dashbord/view/:id",
  component: () => import("../pages/ProductsDash/view"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/products/list",
  component: () => import("../pages/Products"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/product/:product_id/:code/prices",
  component: () => import("../pages/Global/Products/prices.vue"),
});

// routes.push({
//     path: "/price_readjust",
//     component: () =>
//         import ("../pages/PriceReadjust/index.vue"),
// });



routes.push({
  path: "/volumes/:type/:local_id",
  component: () =>
    import("../pages/Volumes"),
});


routes.push({
  path: "/buy",
  component: () => import("../pages/Buy"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/buy/catalog/:id",
  component: () => import("../pages/Buy/catalog.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/buy/catalog/:catalog_id/select/:category_id",
  component: () => import("../pages/Buy/select.vue"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/buy/cart",
  component: () => import("../pages/Buy/units.vue"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/buy/cart/:id",
  component: () => import("../pages/Buy/cart.vue"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/buy/dash",
  component: () => import("../pages/Buy/dash.vue"),
  beforeEnter: requireAuth,
});
routes.push({
  path: "/buy/product",
  component: () => import("../pages/Buy/product-dash.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/checkout/:id",
  component: () => import("../pages/Checkout"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/orders",
  component: () => import("../pages/Orders"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/order/:id/:name",
  component: () => import("../pages/Orders/list"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/order/finish/:id/:name",
  component: () => import("../pages/Orders/finish"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/orders/view/:id",
  component: () => import("../pages/Orders/view"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/orders/items/:id",
  component: () => import("../pages/Orders/items"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/profiles",
  component: () => import("../pages/Profiles"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/approved-suppliers",
  component: () => import("../pages/ApprovedSupplier"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/store_addresses",
  component: () => import("../pages/StoreAddresses"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/store_corridors",
  component: () => import("../pages/StoreCorridors"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/store_spaces",
  component: () => import("../pages/StoreSpaces"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/store_modules",
  component: () => import("../pages/StoreModules"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/store_levels",
  component: () => import("../pages/StoreLevels"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/store_spans",
  component: () => import("../pages/StoreSpans"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/storages",
  component: () => import("../pages/Storages"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_lists",
  component: () =>
      import ("../pages/ToDoList"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_lists/form/:id?",
  component: () =>
      import ("../pages/ToDoList/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_projects",
  component: () =>
      import ("../pages/ToDoProject"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_projects/like",
  component: () =>
      import ("../pages/ToDoLike"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_projects/form/:id?",
  component: () =>
      import ("../pages/ToDoProject/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_categories",
  component: () =>
      import ("../pages/ToDoCategory"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_categories/form/:id?",
  component: () =>
      import ("../pages/ToDoCategory/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_phases",
  component: () =>
      import ("../pages/ToDoPhase"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_phases/form/:to_do_project_id/:id?",
  component: () =>
      import ("../pages/ToDoPhase/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_tasks",
  component: () =>
      import ("../pages/ToDoTask"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_tasks/form/:to_do_phase_id/:id?",
  component: () =>
      import ("../pages/ToDoTask/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_dashboard",
  component: () =>
      import ("../pages/ToDoDashboard"),
  beforeEnter: requireAuth,
});
routes.push({
  path: "/to_do_project_manager",
  component: () =>
      import ("../pages/ToDoProjectManager"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "to_do_project/to_do_phase/:to_do_phase_id/to_do_list/:to_do_list_id",
  component: () => import("../pages/ToDoProject/listPhases.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_project_manager/form/:id?",
  component: () =>
      import ("../pages/ToDoProjectManager/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/finance-dashboard",
  component: () =>
      import("../pages/Finance/index.vue")
  //,
 // beforeEnter: requireAuth,
});

routes.push({
  path: "/banks",
  component: () =>
      import("../pages/Banks/index.vue"),
  //,
  beforeEnter: requireAuth,
});

routes.push({
  path: "/fees",
  component: () =>
      import("../pages/Fees/index.vue"),
  //,
  beforeEnter: requireAuth,
});

routes.push({
  path: "/types_assets",
  component: () =>
      import("../pages/Types_Assets/index.vue"),
  //,
  beforeEnter: requireAuth,
});

routes.push({
  path: "/assets",
  component: () =>
      import("../pages/Assets/index.vue"),
  //,
  beforeEnter: requireAuth,
});

routes.push({
  path: "/flag_cards",
  component: () =>
      import("../pages/FlagCards/index.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/bank_accounts",
  component: () =>
      import("../pages/bank_accounts/index.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/credit_cards",
  component: () =>
      import("../pages/Credit_Cards/index.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/interest_rates",
  component: () =>
      import("../pages/Interest_Rates/index.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/cost_centers",
  component: () =>
      import("../pages/cost_centers/index.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/payment_types",
  component: () =>
      import("../pages/payment_type/index.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/discounts",
  component: () =>
      import("../pages/Discounts/index.vue"),
  //,
  beforeEnter: requireAuth,
});

Vue.use(Router);
export default new Router({
  mode: "history",
  routes,
});

