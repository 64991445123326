import Vue from "vue";
import Router from "vue-router";

const routes = [];

const requireAuth = async (to, from, next) => {
  const data = JSON.parse(localStorage.getItem("user"));
  if (data.user) {
    next();
  } else {
    next("/login");
  }
};

routes.push({
  path: "/dashboard",
  component: () =>
    import("../pages/Developer"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/translate",
  component: () =>
    import("../pages/Translate"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/unity/:id/view",
  component: () => import("../components/Unity/view.vue"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/modules",
  component: () =>
    import("../pages/Modules"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_lists",
  component: () =>
      import ("../pages/ToDoList"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_lists/form/:id?",
  component: () =>
      import ("../pages/ToDoList/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_projects",
  component: () =>
      import ("../pages/ToDoProject"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_projects/like",
  component: () =>
      import ("../pages/ToDoLike"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/to_do_projects/form/:id?",
  component: () =>
      import ("../pages/ToDoProject/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_categories",
  component: () =>
      import ("../pages/ToDoCategory"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_categories/form/:id?",
  component: () =>
      import ("../pages/ToDoCategory/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_phases",
  component: () =>
      import ("../pages/ToDoPhase"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_phases/form/:to_do_project_id/:id?",
  component: () =>
      import ("../pages/ToDoPhase/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_tasks",
  component: () =>
      import ("../pages/ToDoTask"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_tasks/form/:to_do_phase_id/:id?",
  component: () =>
      import ("../pages/ToDoTask/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_dashboard",
  component: () =>
      import ("../pages/ToDoDashboard"),
  beforeEnter: requireAuth,
});
routes.push({
  path: "/to_do_project_manager",
  component: () =>
      import ("../pages/ToDoProjectManager"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_project_manager/form/:id?",
  component: () =>
      import ("../pages/ToDoProjectManager/form.vue"),
  beforeEnter: requireAuth,
});


Vue.use(Router);
export default new Router({
  mode: "history",
  routes,
});