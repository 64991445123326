export default [
    {
        "code": "ps",
        "country": "af",
        "name": "Pashto"
    },
    {
        "code": "sv",
        "country": "se",
        "name": "Sueco"
    },
    {
        "code": "sq",
        "country": "al",
        "name": "Albanês"
    },
    {
        "code": "ar",
        "country": "sa",
        "name": "Árabe"
    },
    {
        "code": "en",
        "country": "gb",
        "name": "Inglês"
    },
    {
        "code": "ca",
        "country": "es",
        "name": "Catalão"
    },
    {
        "code": "pt",
        "country": "br",
        "name": "Português"
    },
    {
        "code": "es",
        "country": "es",
        "name": "Espanhol"
    },
    {
        "code": "hy",
        "country": "am",
        "name": "Armênio"
    },
    {
        "code": "nl",
        "country": "nl",
        "name": "Holandês"
    },
    {
        "code": "de",
        "country": "de",
        "name": "Alemão"
    },
    {
        "code": "az",
        "country": "az",
        "name": "Azerbaijano"
    },
    {
        "code": "bn",
        "country": "bd",
        "name": "Bengali"
    },
    {
        "code": "be",
        "country": "by",
        "name": "Bielorrusso"
    },
    {
        "code": "bs",
        "country": "ba",
        "name": "Bósnio"
    },
    {
        "code": "no",
        "country": "no",
        "name": "Norueguês"
    },
    {
        "code": "ms",
        "country": "my",
        "name": "Malaio"
    },
    {
        "code": "bg",
        "country": "bg",
        "name": "Búlgaro"
    },
    {
        "code": "km",
        "country": "kh",
        "name": "Khmer"
    },
    {
        "code": "zh-CN",
        "country": "cn",
        "name": "Chinês"
    },
    {
        "code": "zh-TW",
        "country": "hk",
        "name": "Hong Kong"
    },
    {
        "code": "hr",
        "country": "hr",
        "name": "Croata"
    },
    {
        "code": "el",
        "country": "gr",
        "name": "Grego"
    },
    {
        "code": "cs",
        "country": "cz",
        "name": "Tcheco"
    },
    {
        "code": "da",
        "country": "dk",
        "name": "Dinamarquês"
    },
    {
        "code": "fr",
        "country": "fr",
        "name": "Francês"
    },
    {
        "code": "ti",
        "country": "er",
        "name": "Tigrínia"
    },
    {
        "code": "et",
        "country": "ee",
        "name": "Estoniano"
    },
    {
        "code": "am",
        "country": "et",
        "name": "Amárico"
    },
    {
        "code": "fo",
        "country": "fo",
        "name": "Faroês"
    },
    {
        "code": "fi",
        "country": "fi",
        "name": "Finlandês"
    },
    {
        "code": "ka",
        "country": "ge",
        "name": "Georgiano"
    },
    {
        "code": "kl",
        "country": "gl",
        "name": "Groenlandês"
    },
    {
        "code": "ht",
        "country": "ht",
        "name": "Crioulo Haitiano"
    },
    {
        "code": "it",
        "country": "it",
        "name": "Italiano"
    },
    {
        "code": "hu",
        "country": "hu",
        "name": "Húngaro"
    },
    {
        "code": "is",
        "country": "is",
        "name": "Islandês"
    },
    {
        "code": "hi",
        "country": "in",
        "name": "Hindi"
    },
    {
        "code": "id",
        "country": "id",
        "name": "Indonésio"
    },
    {
        "code": "fa",
        "country": "ir",
        "name": "Persa"
    },
    {
        "code": "he",
        "country": "il",
        "name": "Hebraico"
    },
    {
        "code": "ja",
        "country": "jp",
        "name": "Japonês"
    },
    {
        "code": "kk",
        "country": "kz",
        "name": "Cazaque"
    },
    {
        "code": "sw",
        "country": "ke",
        "name": "Suaíli"
    },
    {
        "code": "ko",
        "country": "kr",
        "name": "Coreano"
    },
    {
        "code": "ky",
        "country": "kg",
        "name": "Quirguiz"
    },
    {
        "code": "lo",
        "country": "la",
        "name": "Lao"
    },
    {
        "code": "lv",
        "country": "lv",
        "name": "Letão"
    },
    {
        "code": "lt",
        "country": "lt",
        "name": "Lituano"
    },
    {
        "code": "lb",
        "country": "lb",
        "name": "Árabe libanês"
    },
    {
        "code": "mk",
        "country": "mk",
        "name": "Macedônio"
    },
    {
        "code": "mg",
        "country": "mg",
        "name": "Malgaxe"
    },
    {
        "code": "dv",
        "country": "mv",
        "name": "Divehi"
    },
    {
        "code": "mt",
        "country": "mt",
        "name": "Maltês"
    },
    {
        "code": "ro",
        "country": "ro",
        "name": "Romeno"
    },
    {
        "code": "mn",
        "country": "mn",
        "name": "Mongol"
    },
    {
        "code": "sr",
        "country": "rs",
        "name": "Sérvio"
    },
    {
        "code": "my",
        "country": "mm",
        "name": "Birmanês"
    },
    {
        "code": "ne",
        "country": "np",
        "name": "Nepalês"
    },
    {
        "code": "ur",
        "country": "pk",
        "name": "Urdu"
    },
    {
        "code": "tl",
        "country": "ph",
        "name": "Tagalo"
    },
    {
        "code": "pl",
        "country": "pl",
        "name": "Polonês"
    },
    {
        "code": "ru",
        "country": "ru",
        "name": "Russo"
    },
    {
        "code": "rw",
        "country": "rw",
        "name": "Kinyarwanda"
    },
    {
        "code": "sk",
        "country": "sk",
        "name": "Eslovaco"
    },
    {
        "code": "sl",
        "country": "si",
        "name": "Esloveno"
    },
    {
        "code": "so",
        "country": "so",
        "name": "Somali"
    },
    {
        "code": "af",
        "country": "za",
        "name": "Africâner"
    },
    {
        "code": "si",
        "country": "lk",
        "name": "Cingalês"
    },
    {
        "code": "tg",
        "country": "tj",
        "name": "Tajique"
    },
    {
        "code": "th",
        "country": "th",
        "name": "Tailandês"
    },
    {
        "code": "tr",
        "country": "tr",
        "name": "Turco"
    },
    {
        "code": "tk",
        "country": "tm",
        "name": "Turcomeno"
    },
    {
        "code": "uk",
        "country": "ua",
        "name": "Ucraniano"
    },
    {
        "code": "vi",
        "country": "vn",
        "name": "Vietnamita"
    }
]